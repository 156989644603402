import React from "react"
// import { FaFacebookF, FaTwitter, FaInstagram } from 'react-icons/fa';

interface FooterIcon {
  icon: JSX.Element
  css: string
}

const Footer: React.FunctionComponent = () => {
  // const icons: Array<FooterIcon> = [
  //     { icon: <FaFacebookF />, css: 'hover:bg-blue-800' },
  //     { icon: <FaTwitter />, css: 'hover:bg-blue-500' },
  //     { icon: <FaInstagram />, css: 'hover:bg-orange-800' },
  // ];

  return (
    <footer className="flex justify-between bg-gray-700 items-center shadow-xl p-4">
      <p className="text-gray-500">
        © 2014 Point & Click LLC. All Rights Reserved.
      </p>
      <p className="text-xs text-gray-800">v 1.15</p>
      {/* <div className="py-10"></div> */}
      {/* <div className="text-3xl">Follow us</div>
            <div className="flex my-4">
                {
                    icons.map((ic, i) =>
                        <div key={i}
                            className={`cursor-pointer transform transition duration-500 ease-in-out hover:-translate-y-1 text-2xl text-white bg-gray-800 p-4 mx-2 rounded-full ${ic.css}`}>
                            {ic.icon}
                        </div>
                    )
                }
            </div> */}
    </footer>
  )
}

export default Footer
