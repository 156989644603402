import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

interface HeaderProps {
  page: string
}

interface HeaderRouter {
  title: string
  to: string
  bg: string
}

const Header: React.FunctionComponent<HeaderProps> = props => {
  const data = useStaticQuery(
    graphql`
      query {
        logo: file(relativePath: { eq: "logo.png" }) {
          childImageSharp {
            fixed(width: 150) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  )

  const routers: Array<HeaderRouter> = [
    { title: "Apps", to: "/apps", bg: 'url("/tabs/tab_blue.png")' },
    { title: "About", to: "/about", bg: 'url("/tabs/tab_green.png")' },
    {
      title: "Coming Soon",
      to: "/coming-soon",
      bg: 'url("/tabs/tab_pink.png")',
    },
    {
      title: "Contact Us",
      to: "/contact-us",
      bg: 'url("/tabs/tab_purple.png")',
    },
    { title: "Privacy", to: "/privacy", bg: 'url("/tabs/tab_red.png")' },
    {
      title: "Terms of Service",
      to: "/terms-of-service",
      bg: 'url("/tabs/tab_yellow.png")',
    },
  ]

  return (
    <header className="flex flex-col md:flex-row items-center bg-gray-800 shadow-xl justify-center px-2 py-2">
      <Link to={"/"} className="px-5 pt-2">
        <Img fixed={data.logo.childImageSharp.fixed} />
      </Link>
      <div className="flex">
        {routers.map((r, i) => (
          <Link to={r.to} key={i}>
            <div
              style={{ backgroundImage: r.bg }}
              className={`${props.page === r.title &&
                "font-bold text-white"} uppercase header-link-sm md:header-link transform hover:-translate-y-1 transition duration-500 ease-in-out hover:text-white cursor-pointer flex items-center justify-center text-center text-xs md:text-md`}
            >
              {r.title}
            </div>
          </Link>
        ))}
      </div>
    </header>
  )
}

export default Header
